// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery'
import('src/plugins')
import "controllers"
// import 'popper.js'
// import 'bootstrap'
// import "jquery/dist/jquery.min"
// import "bootstrap/dist/js/bootstrap.bundle.min"
// import "jquery-mask-plugin/dist/jquery.mask.min"

// import "../src/dashkit/polyfills"
// import "./dashkit/charts"
// import "../src/dashkit/autosize"
// import "../src/dashkit/dropdowns"
// import "./dashkit/dropzone"
// import "../src/dashkit/flatpickr"
// import "../src/dashkit/highlight"
// import "./dashkit/kanban"
// import "../src/dashkit/list"
// import "./dashkit/map"
// import "../src/dashkit/navbar"
// import "../src/dashkit/popover"
// import "./dashkit/quill"
// import "../src/dashkit/select2"
// import "../src/dashkit/tooltip"
// import "../src/dashkit/checklist"
// import "./dashkit/wizard"
// import "./dashkit/dashkit"
// import "iframe-resizer/js/iframeResizer.min"

// customizations and homemade
// import "../src/widget_date_picker"
// import "../src/tracking_site_selector"
// import "../src/segment_caching"
// import "../src/iframeresize"


import "../stylesheets/application"

// Uncomment to copy all static images under ../img to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../img', true)
const imagePath = (name) => images(name, true)

const fonts = require.context('../fonts', true)
const fontPath = (name) => fonts(name, true)

