import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["segmentId", "deleteSegmentButton", "form"]

	connect() {
		const segmentId = this.segmentIdTarget.value;
		const deleteButton = this.deleteSegmentButtonTarget;
		deleteButton.style.display = segmentId ? "block" : "none";
	}

	change(event) {
		event.preventDefault();
		const selectedId = event.target.value;
		this.segmentIdTarget.value = selectedId; 
		this.formTarget.requestSubmit();

	}

	delete() {
		let form = this.formTarget;
		form.querySelector('select[name="segment_id"]').value = '';

		const changeEvent = new Event('change');
		form.querySelector('select[name="segment_id"]').dispatchEvent(changeEvent);

		form.submit();


	}

}
