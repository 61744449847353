import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["passwordInput"]

	password(e) {
		const input = this.passwordInputTarget;

		if (input.type === "password") {
			e.target.classList.remove('fe-eye-off');
			e.target.classList.add('fe-eye');
			input.type = "text";
		} else {
			e.target.classList.remove('fe-eye');
			e.target.classList.add('fe-eye-off');
			input.type = "password";
		}
	}
}
