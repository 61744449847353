import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["membershipId"]
  
  connect() {
		console.log("Hello from Membership switch controller");
    console.log("Controller connected to:", this.element);
	}

  switchMembership(event) {
    let spinner = "<div class='row justify-content-center'><div class='col-auto align-self-center'><div class='spinner-border text-primary' role='status'><span class='sr-only'>Loading...</span></div></div></div>";

    console.log("Entering membership selector");
    let selectedMembershipId = event.target.value;
    const switchUrl = this.element.dataset.url; 
    console.log(switchUrl);
    const url = switchUrl + selectedMembershipId;
  
    // Print the selected value on the console
    console.log("Selected value:", selectedMembershipId);
    $.post(url, function(data) {
      window.location.href = data.redirect_to
    });

  }
}